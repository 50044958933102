<template>
  <div class="features">
    <div class="responsive-container">
      <div class="feature" v-for="feature in featuresData" :key="feature.name">
        <div :class="['image', feature.image]"></div>
        <div class="text-container">
          <h2 v-html="feature.name"></h2>
          <p v-html="feature.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import featuresData from '@/data/features.json';

export default {
  name: 'features',
  data() {
    return {
      featuresData,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.features {
  margin-bottom: 80px;
  .feature {
    display: flex;
    // align-items: center;
    padding: 60px 100px;
    // border: 1px solid rgba(0, 136, 255, 0.5);
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    // box-shadow: 0px 0px 6px 0px #0088ff73;
    box-shadow: 0px 0px 6px 0px rgba(205, 205, 205, 0.45);
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
    .image {
      // display: none;
      width: 160px;
      height: 160px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      &.money-hand {
        background-image: url('../assets/money-hand.svg');
      }
      &.pie-slice {
        background-image: url('../assets/pie-slice.svg');
      }
      &.browser {
        background-image: url('../assets/secure.svg');
      }
      &.savings {
        background-image: url('../assets/savings.svg');
      }
      &.money-chart {
        background-image: url('../assets/money-chart.svg');
      }
      &.more {
        background-image: url('../assets/more.svg');
      }
      &.crypto {
        background-image: url('../assets/crypto.png');
      }
      &.shield {
        background-image: url('../assets/shield.png');
      }
      &.mobile-phone {
        background-image: url('../assets/mobile-phone.png');
      }
      &.stopwatch {
        background-image: url('../assets/stopwatch.png');
      }
      &.tabs {
        background-image: url('../assets/tabs.png');
      }
      &.malware {
        background-image: url('../assets/malware.png');
      }
      &.ssl {
        background-image: url('../assets/ssl.png');
      }
      &.sunglasses {
        background-image: url('../assets/sunglasses.png');
      }
      &.happy-users {
        background-image: url('../assets/happy-users.png');
      }
      &.safe {
        background-image: url('../assets/safe.png');
      }
      &.battery {
        background-image: url('../assets/battery.png');
      }
      &.block {
        background-image: url('../assets/block.png');
      }
    }
    .text-container {
      flex: 1;
      text-align: left;
      margin-left: 60px;
      h2 {
        font-size: 2em;
        font-weight: 600;
        margin: 0;
        margin-bottom: 16px;
      }
      p {
        font-size: 1.2em;
        line-height: 2em;
        margin: 0;
      }
    }
  }
  .disclaimer {
    text-align: center;
    opacity: 0.6;
    line-height: 2em;
  }
}
@media only screen and (max-width: 768px) {
  .features {
    margin-bottom: 80px;
    h1 {
      font-size: 2em;
      margin: 0;
      margin-bottom: 0.8em;
    }
    .images {
      flex-direction: column;
      align-items: center;
      .image {
        margin-right: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .features {
    .feature {
      flex-direction: column;
      padding: 40px 30px;
      align-items: center;
      .image {
        width: 100px;
        height: 100px;
        margin-bottom: 30px;
        background-position: center;
      }
      .text-container {
        text-align: center;
        margin-left: 0;
        h2 {
          font-size: 1.6em;
        }
        p {
          font-size: 1.1em;
        }
      }
    }
  }
}
</style>
