<template>
  <div class="about-us" :class="{ 'simple': 'simple' in this.$route.query }">
    <Header :allowSimple="true" />
    <HeaderTitle title="About Us" :centered="true" :allowSimple="true" />
    <div class="responsive-container">
      <div class="content">
        <img class="office" src="@/assets/office.png" />
        <p>At Pie, our mission is to deliver simple, streamlined, fast web browsing experiences to users around the world.</p>
        <p>We're funded by Y Combinator Startup School and supported by Atlanta Tech Village.</p>
        <Supporters/>
        <p>
          We were founded in 2020 as a Delaware US corporation and our headquarters is based in Atlanta,
          Georgia. You can reach us via email at <a href="mailto:contact@piebrowser.com">contact@piebrowser.com</a> or via mail at:
        </p>
        <p>
          <b>Pie Technologies Inc.</b><br/>
          3423 Piedmont Rd NE<br/>
          Suite 595<br/>
          Atlanta, GA 30326
        </p>
      </div>
    </div>
    <Footer :showFaqs="false" :allowSimple="true" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import HeaderTitle from '@/components/HeaderTitle.vue';
import Supporters from '@/components/Supporters.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'AboutUs',
  components: {
    Header,
    HeaderTitle,
    Supporters,
    Footer,
  },
};
</script>

<style lang="scss">
@import '@/styles/colors.scss';
.about-us {
  .content {
    margin: 0 auto;
    margin-bottom: 60px;
    max-width: 600px;
    .office {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 20px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4);
    }
    p {
      font-size: 1em;
      line-height: 1.8em;
      text-align: center;
      a {
        color: inherit;
      }
    }
    .supporters {
      margin: 40px 0;
      h1 {
        display: none;
      }
    }
  }
  &.simple {
    .content {
      .office {
        margin-top: 20px;
      }
      a {
          text-decoration: none;
          color: inherit;
          pointer-events: none;
      }
    }
  }
}
</style>
