<template>
  <div class="header" v-if="show">
    <div class="responsive-container">
      <router-link class="logo" to="/"></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["allowSimple"],
  computed: {
    show() {
      return this.allowSimple ? !("simple" in this.$route.query) : true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.header {
  padding: 20px 0;
  .responsive-container {
    display: flex;
    .logo {
      width: 85px;
      height: 34px;
      background-image: url("../assets/pie-logo-2.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      &:active {
        transform: translateY(1px);
        opacity: 0.8;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .header {
    padding: 16px 0;
    .responsive-container {
      justify-content: center;
      .logo {
        width: 67px;
        height: 27px;
      }
    }
  }
}
</style>
